/**
 * Student Sharing JS
 *
 * @package LifterLMS_Social_Learning/Scripts
 *
 * @since 1.0.0
 * @version 1.6.0
 */

( function( $ ){

	/**
	 * Story-related interactions
	 *
	 * @since 1.0.0
	 */
	var StoryInteractions = function() {

		/**
		 * Clear an error message
		 *
		 * @since 1.0.0
		 *
		 * @param {object} $error The jQuery element for an error message to clean.
		 * @return {void}
		 */
		function clear_error( $error ) {
			$error.html( '' );
		}

		/**
		 * Initiliaze LLMS Donuts on stories
		 *
		 * @since 1.0.0
		 *
		 * @return {void}
		 */
		function donuts() {

			$( '.llms-sl-card--story-pass .llms-donut' ).each( function() {
				LLMS.Donut( $( this ) );
			} );

		}

		/**
		 * Move the cursor to the end of an contenteditable element
		 *
		 * @since 1.1.0
		 *
		 * @param {object} $el The jQuery element of a contenteditable node.
		 * @return {void}
		 */
		function focus_end( $el ) {

			var el = $el[0];

			el.focus();

			if ( typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined' ) {

				var range = document.createRange();
				range.selectNodeContents( el );
				range.collapse( false );

				var sel = window.getSelection();
				sel.removeAllRanges();
				sel.addRange( range );

			} else if (typeof document.body.createTextRange != "undefined") {

				var textRange = document.body.createTextRange();
				textRange.moveToElementText( el );
				textRange.collapse( false );
				textRange.select();

			}

		}

		/**
		 * Show an error message
		 *
		 * @since 1.0.0
		 *
		 * @param {object} $error The jQuery element for an error message to show.
		 * @param {string} msg    Error message text/string.
		 * @return {void}
		 */
		function show_error( $error, msg ) {
			$error.html( LLMS.l10n.translate( 'Error' ) + ': ' + msg );
		};

		/**
		 * General method for posting story related data to the server via ajax
		 *
		 * Automatically starts a spinner on a parent element.
		 *
		 * @since 1.0.0
		 *
		 * @param {object}   data    Data to post to the server.
		 * @param {object}   $parent The jQuery element for the parent node.
		 * @param {function} cb      Callback function.
		 * @param {string}   size    Optional spinner size (small).
		 * @return {void}
		 */
		function post( data, $parent, cb, size ) {

			LLMS.Ajax.call( {
				data: data,
				beforeSend: function() {

					LLMS.Spinner.start( $parent, size );

				},
				error: function( xhr, status, error ) {

					var msg = '';

					if ( 'object' === typeof error && error.message ) {
						msg = error.message;
					} else {
						msg = error;
					}

					cb( {
						message: msg,
						success: false,
						data: {
							status: status,
							error: error,
						}
					} );

					console.error( status, error );

					LLMS.Spinner.stop( $parent );

				},

				success: function( r ) {

					LLMS.Spinner.stop( $parent );
					cb( r );

				}

			} );

		};

		/**
		 * Remove paragraphs from a content editable element
		 *
		 * @since 1.0.0
		 *
		 * @param {object} $html The jQuery element for the node to remove <p>s from.
		 * @return {string}
		 */
		function unautop( $html ) {

			$html.find( 'p' ).each( function() {
				$( this ).replaceWith( $( this ).text() + "\r\n" );
			} );

			return $html;

		};

		/**
		 * Handle filtering via select element on instructor's filter card.
		 *
		 * @since 1.1.0
		 */
		var Filtering = function() {

			/**
			 * Bind dom events.
			 *
			 * @since 1.1.0
			 * @since 1.6.0 Use `window.llms.ajax_nonce` in place of `wp_ajax_data.nonce`.
			 *              Allow clearing selection: reloads the timeline.
			 *
			 * @return {void}
			 */
			function bind() {

				$el.llmsSelect2( {
					allowClear: true,
					ajax: {
						dataType: 'JSON',
						delay: 250,
						method: 'POST',
						url: window.llms.ajaxurl,
						data: function( params ) {
							return {
								action: 'llms_sl_filter_posts',
								term: params.term,
								page: params.page,
								_ajax_nonce: window.llms.ajax_nonce,
							};
						},
						error: function( xhr, status, error ) {
							console.log( status, error );
						},
					},
					dropdownParent: $( '#llms-sl-card--timeline-filters-instructor' ),
					placeholder: LLMS.l10n.translate( 'Search by title...' ),
					width: '100%',
				} ).on( 'select2:select', function( e ) {
					window.location = e.params.data.url;
				} ).on( 'select2:unselect', function() {
					window.location = $el.data( 'timeline-url' );
				});

			};

			var $el = $( '#llms-sl-instructor-filter-post' );

			if ( $el.length ) {

				// Bind dom events.
				bind();

			}

		};

		/**
		 * User interactions for managing (editing and deleting) existing stories
		 *
		 * @since 1.0.0
		 * @since 1.2.1 Unknown.
		 */
		var Managing = function() {

			var	cached_html;

			/**
			 * Bind DOM events
			 *
			 * @since 1.0.0
			 * @since 1.2.1 Unknown.
			 * @since 1.3.0 Changed parent's selector from `'.llms-sl-timeline-main'` to `'.llms-sl-timeline'`
			 *              so to target cards in profile's tab content too.
			 *
			 * @return {void}
			 */
			function bind() {

				$parent = $( '.llms-sl-timeline-main, .llms-sl-profile-main' );

				// Prevent click events from hashing to the top.
				$parent.on( 'click', '.llms-sl-card-action.llms-sl-has-context-menu.manage-card', function( e ) {
					e.preventDefault();
				} );

				// Make the story editable.
				$parent.on( 'click', '.llms-sl-card--story .llms-sl-context-menu a.edit-story', function( e ) {

					e.preventDefault();
					var $btn = $( this ),
					$story   = $btn.closest( '.llms-sl-card--story' ),
					$main    = $story.find( '.llms-sl-card-main' ),
					$text    = $main.find( '.llms-sl-card-text' );

					if ( cached_html.$el && cached_html.html ) {

						cached_html.$el.find( 'button.llms-sl-edit-story-cancel' ).trigger( 'click' );

					}

					set_cached_html( $story, $text.html() );

					$main.find( '.llms-sl-edit-story-actions' ).show();

					var text = unautop( $text ).text().trim();
					$text.html( text );

					$text.attr( 'contenteditable', true );

					focus_end( $text );

					// On keyup enable or disable the save button.
					$text.on( 'keyup', function( e ) {

						var $save = $main.find( 'button.llms-sl-edit-story-submit' );

						if ( $text[0].innerText != text && $text[0].innerText.length ) {
							$save.removeAttr( 'disabled' );
						} else {
							$save.attr( 'disabled', 'disabled' );
						}

					} );

				} );

				// Cancel a story edit.
				$parent.on( 'click', '.llms-sl-card--story button.llms-sl-edit-story-cancel', function( e ) {

					e.preventDefault();
					var $btn = $( this ),
					$story   = $btn.closest( '.llms-sl-card--story' ),
					$main    = $story.find( '.llms-sl-card-main' ),
					$text    = $main.find( '.llms-sl-card-text' );

					$main.find( '.llms-sl-edit-story-actions' ).hide();
					$text.attr( 'contenteditable', false );
					$text.html( cached_html.html );

					set_cached_html(); // Clear the cache.

				} );

				// Save story edits to the database.
				$parent.on( 'click', '.llms-sl-card--story button.llms-sl-edit-story-submit', function( e ) {

					e.preventDefault();

					var $btn = $( this ),
					$story   = $btn.closest( '.llms-sl-card--story' ),
					$main    = $story.find( '.llms-sl-card-main' ),
					$text    = $main.find( '.llms-sl-card-text' ),
					$error   = $main.find( '.llms-sl-card-error' ),
					story_id = $story.attr( 'data-story-id' ),
					data     = {
						action: 'llms_sl_story_edit',
						story_id: story_id,
						nonce: $( '#llms-sl-edit-story-nonce-' + story_id ).val(),
						content: $text[0].innerText,
					};

					clear_error( $error );
					post( data, $main, function( r ) {

						if ( ! r ) {

							show_error( $error, LLMS.l10n.translate( 'An unknown error occurred, please try again.' ) );

						} else if ( r && ! r.success ) {

							show_error( $error, r.message );

						} else if ( r.data && r.data.html ) {

							$main.find( '.llms-sl-edit-story-actions' ).hide();
							$text.attr( 'contenteditable', false );
							$text.html( r.data.html );
							set_cached_html();

						}

					} );

				} );

				// Delete a story.
				$parent.on( 'click', '.llms-sl-card--story .llms-sl-context-menu a.delete-story', function( e ) {

					e.preventDefault();
					if ( window.confirm( LLMS.l10n.translate( 'Are you sure you want to delete this story? This action cannot be undone.' ) ) ) {

						var $story = $( this ).closest( '.llms-sl-card--story' ),
						data       = {
							action: 'llms_sl_story_delete',
							story_id: $story.attr( 'data-story-id' ),
						};

						post( data, $story, function( r ) {

							if ( ! r ) {

								alert( LLMS.l10n.translate( 'An unknown error occurred, please try again.' ) );

							} else if ( r && ! r.success ) {

								alert( r.message );

							} else {

								$story.fadeOut( 200 );

							}

						} );

					}

				} );

			};

			/**
			 * Set the cached html for restoring cancelled edits
			 *
			 * Use set_cached_html() to clear the cache.
			 *
			 * @since 1.0.0
			 *
			 * @param {object} $el  The jQuery element of a story card node.
			 * @param {string} html The .html() of the story.
			 */
			function set_cached_html( $el, html ) {
				cached_html = {
					$el: $el,
					html: html,
				};
			}

			// Setup empty cache on init.
			set_cached_html();

			// Bind dom events.
			bind();

		};

		/**
		 * Paging related story interactions
		 *
		 * Autoloads stories when scrolling on timelines.
		 *
		 * @since 1.0.0
		 */
		var Paging = function() {

			var load_interval = 14,
			next_load_i       = 0,
			loading           = false,
			next_offset;

			/**
			 * Bind dom events
			 *
			 * @since 1.0.0
			 *
			 * @return {void}
			 */
			function bind() {

				set_next_load_element();

				$( window ).on( 'scroll', function() {
					if ( ! loading && $( window ).scrollTop() >= next_offset ) {
						$( '#llms-sl-timeline-load-more' ).trigger( 'click' );
					}
				} );

				$( '.llms-sl' ).on( 'click', '#llms-sl-timeline-load-more', function() {

					loading = true;

					var $btn = $( this ),
					data     = {
						action: 'llms_sl_timeline_load_more',
						args: JSON.parse( $btn.attr( 'data-args' ) )
					};

					data.args.page++;

					post( data, $btn, function( r ) {

						if ( ! r ) {
							show_error( $error, LLMS.l10n.translate( 'An unknown error occurred, please try again.' ) );
						} else if ( r && ! r.success ) {
							show_error( $error, r.message );
						} else if ( r.data && r.data.html ) {

							loading = false;

							var $stories = $( r.data.html ),
							r            = new Replying( $stories.find( '.llms-sl-story-reply-comment' ) );

							$btn.hide();
							$btn.parent().append( $stories );
							donuts();
							$btn.remove();

							set_next_load_element();

						}

					}, 'small' );

				} );

			};

			/**
			 * Setup the next load element
			 *
			 * @since 1.0.0
			 *
			 * @return {void}
			 */
			function set_next_load_element() {

				next_load_i = next_load_i + load_interval;

				var $el = $( '.llms-sl-timeline .llms-sl-card--story' ).eq( next_load_i );

				if ( $el.length ) {
					next_offset = $el.offset().top;
				}

			}

			bind();

		};

		/**
		 * Interactions related to posting replies to stories
		 *
		 * @param {object} $fields The jQuery element of a reply field parent node.
		 *
		 * @since 1.0.0
		 */
		var Replying = function( $fields ) {

			var $fields = $fields || $( '.llms-sl-story-reply-comment' );

			/**
			 * Bind DOM events
			 *
			 * @since 1.0.0
			 *
			 * @return {void}
			 */
			function bind() {

				$fields.each( function() {

					var $field = $( this );

					$field.on( 'keydown', function( event ) {

						var key = event.which || event.keyCode;

						switch ( key ) {

							case 13: // Enter.
								event.preventDefault();
								if ( get_content( $field ) ) {
									share( $field );
								}
							break;

							case 27: // Escape.
								event.target.blur();
							break;

						}

					} );

				} );

			};

			/**
			 * Add the HTML for a new reply after it's been saved via AJAX
			 *
			 * @since 1.0.0
			 *
			 * @param {object} $card The jQuery element of the story card to add the reply to.
			 * @param {string} html  Html of the reply.
			 */
			function add_reply_html( $card, html ) {

				$card.find( '.llms-sl-story-replies' ).append( html );

			};

			/**
			 * Retrieve the content of a reply from the reply text field element
			 *
			 * @since 1.0.0
			 *
			 * @param {object} $field The jQuery element of the text field node.
			 * @return {string}
			 */
			function get_content( $field ) {
				return $field[0].innerText;
			};

			/**
			 * Save a story to the DB via ajax
			 *
			 * @since 1.0.0
			 *
			 * @param {object} $field The jQuery element of the field.
			 * @return {void}
			 */
			function share( $field ) {

				var $parent = $field.closest( '.llms-sl-story-reply' ),
				$card       = $field.closest( '.llms-sl-card--story' ),
				$error      = $card.find( '.llms-sl-card-footer .llms-sl-card-error' ),
				story_id    = $card.attr( 'data-story-id' );

				var data = {
					action: 'llms_sl_story_reply',
					story_id: story_id,
					nonce: $( '#llms-sl-reply-nonce-' + story_id ).val(),
					content: get_content( $field ),
				};

				clear_error( $error );
				post( data, $parent, function( r ) {

					if ( ! r ) {
						show_error( $error, LLMS.l10n.translate( 'An unknown error occurred, please try again.' ) );
					} else if ( r && ! r.success ) {
						show_error( $error, r.message );
					} else if ( r.data && r.data.html ) {

						$field.text( '' ).blur();
						add_reply_html( $card, r.data.html );

					}

				}, 'small' );

			};

			bind();

		};

		/**
		 * Interactions for sharing a new story
		 *
		 * @since 1.0.0
		 */
		var Sharing = function() {

			var $card = $( '#llms-sl-card--new-share' ),
			$btn      = $( '#llms-sl-new-share-submit' ),
			$content  = $( '#llms-sl-new-share-content' ),
			$error    = $card.find( '.llms-sl-card-error' );

			/**
			 * Bind DOM events
			 *
			 * @since 1.0.0
			 *
			 * @return {void}
			 */
			function bind() {

				$content.on( 'keyup', function( ) {

					if ( ! get_content() ) {
						$btn.attr( 'disabled', 'disabled' );
					} else {
						$btn.removeAttr( 'disabled' );
					}

				} );

				$btn.on( 'click', function() {

					if ( ! get_content() ) {
						return;
					}

					share();

				} );

			};

			/**
			 * Retrieve the share content
			 *
			 * @since 1.0.0
			 *
			 * @return {string}
			 */
			function get_content() {
				return $content[0].innerText;
			};

			/**
			 * Share a new story via AJAX
			 *
			 * @since 1.0.0
			 *
			 * @return {void}
			 */
			function share() {

				var data = {
					action: 'llms_sl_new_share',
					nonce: $( '#llms-sl-new-share-nonce' ).val(),
					noun: $( '#llms-sl-new-share-noun' ).val(),
					content: get_content(),
				};

				clear_error( $error );
				post( data, $card, function( r ) {

					if ( ! r ) {
						show_error( $error, LLMS.l10n.translate( 'An unknown error occurred, please try again.' ) );
					} else if ( r && ! r.success ) {
						show_error( $error, r.message );
					} else {
						LLMS.Spinner.start( $card );
						window.location.reload();
					}

				} );

			};

			bind();

		};

		var f = new Filtering(),
		m     = new Managing(),
		p     = new Paging(),
		r     = new Replying(),
		s     = new Sharing();

		donuts();

	};

	var a = new StoryInteractions();

} )( jQuery );
